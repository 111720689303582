import Vue from "vue";
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
    state:{
        token: localStorage.getItem('Authorization')?localStorage.getItem('Authorization'):''
    },

    mutations:{
        $_setToken(state, value) { // 设置存储token
	        state.token = value;
	        localStorage.setItem('Authorization', value);
	    },
	    $_removeStorage(state, value){  // 删除token
		      localStorage.removeItem('Authorization');
	    },
    },

    getters:{    // 监听数据变化的
		getStorage(state){   // 获取本地存储的登录信息
	      if(!state.token){
	        state.token =JSON.parse(localStorage.getItem('Authorization'))
	      }
	      return state.token
	    }
	},
})

export default store;