import request from "../utils/request";

export function login(username, password) {
  return request({
    url: "/api/users/login/",
    method: "POST",
    data: {
      username,
      password,
    },
  });
}
