<template>
  <div>
    <!-- 新增 -->
    <el-row class="rowButton">
      <el-button type="primary" @click="dialogVisible = true"
        >新增部门</el-button
      >

      <!--  下载模板 -->
      <el-button
        type="warning"
        size="medium"
        class="download"
        @click="downloadTemplate"
        >下载模板</el-button
      >

      <el-button type="primary" size="small" @click="downloadTableData"
        >导出EXCEL</el-button
      >

      <el-upload
        class="upload"
        action="#"
        :show-file-list="false"
        :on-change="importTableData"
        accept="'.xlsx','.xls'"
        :auto-upload="false"
      >
        <el-button size="small" type="primary" class="importButton"
          >导入EXCEL</el-button
        >
      </el-upload>

      <!-- 下载模板a标签 -->
      <a
        ref="downloadTemplate"
        style="display: none"
        href="./static/excelTemplate.xls"
      ></a>
    </el-row>

    <el-dialog
      style="text-align: left"
      title="新增部门"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form
        style="text-align: left; width: 300px"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
      >
        <el-form-item label="部门名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span style="text-align: center" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="insert('ruleForm')">保 存</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 显示部门全部数据 -->
    <el-table style="text-align: center" :data="tableData" border>
      <el-table-column
        type="index"
        :index="indexMethod"
        prop="id"
        label="序号"
        width="85"
      ></el-table-column>
      <el-table-column prop="name" label="部门名称" width="260">
      </el-table-column>
      <el-table-column
        prop="updateTime"
        label="最后更新日期"
        width="300"
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleEdit(scope.row)"
            >编辑</el-button
          >
          <el-dialog
            style="text-align: left"
            title="编辑部门"
            :visible.sync="dialogUpdateVisible"
            width="30%"
            :before-close="handleUpdateClose"
          >
            <el-form
              style="text-align: left; width: 300px"
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="80px"
              class="demo-ruleForm"
            >
              <el-form-item label="部门名称" prop="name">
                <el-input v-model="ruleForm.name"></el-input>
              </el-form-item>
            </el-form>
            <span
              style="text-align: center"
              slot="footer"
              class="dialog-footer"
            >
              <el-button type="primary" @click="update('ruleForm')"
                >保 存</el-button
              >
              <el-button @click="handleUpdateClose()">取 消</el-button>
            </span>
          </el-dialog>
          <el-button type="danger" size="mini" @click="handleDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <br />
    <!-- 分页条 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage4"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  queryDept,
  addDept,
  updateDept,
  deleteDept,
  downloadFile,
  importFile,
} from "../api/department";

export default {
  name: "department",
  data() {
    return {
      tableData: [],
      currentPage4: 1,
      pageSize: 10,
      total: 0,
      ruleForm: {
        name: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入部门名称", trigger: "blur" },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: "blur",
          },
        ],
      },
      dialogVisible: false,
      dialogUpdateVisible: false,
    };
  },
  methods: {
    //文件模板下载
    downloadTemplate() {
      // let a = document.createElement("a");
      // a.href = "./static/excelTemplate.xls";
      // a.download = "excelTemplate.xls";
      // a.style.display = "none";
      // document.body.appendChild(a);
      // a.click();
      // a.remove();
      this.$refs.downloadTemplate.dispatchEvent(new MouseEvent("click"));
    },
    //excel导出
    downloadTableData() {
      const temp = {
        id: this.tableData.id,
        name: this.tableData.name,
        createTime: this.tableData.createTime,
        updateTime: this.tableData.updateTime,
      };
      downloadFile().then(function (res) {
        if (!res) {
          return;
        }
        // 创建a标签
        const link = document.createElement("a");
        // 初始化a标签
        let blob = new Blob([res], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);

        link.setAttribute("download", "部门信息表" + ".xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    //excel导入
    importTableData(file) {
      let formData = new FormData(); //声明一个FormDate对象
      formData.append("file", file.raw); //把文件信息放入对象中
      //调用后台导入的接口
      importFile(formData)
        .then((res) => {
          console.log(res);
          if (res) {
            this.$message({
              type: "success",
              message: "导入成功",
            });
            this.$router.go(0); // 导入表格之后可以获取导入的数据渲染到页面，此处的方法是获取导入的数据
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "导入失败",
          });
        });
    },
    //查询
    load() {
      const params = new URLSearchParams();
      params.append("page", this.currentPage4);
      params.append("pageSize", this.pageSize);
      queryDept(params).then((res) => {
        // console.log(res.data);
        this.tableData = res.data.rows;
        this.total = res.data.total;
      });
    },
    indexMethod(index) {
      return index + 1;
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      console.log(`每页 ${pageSize} 条`);
      this.load();
    },
    handleCurrentChange(pageNum) {
      this.currentPage4 = pageNum;
      console.log(`当前页: ${pageNum}`);
      this.load();
    },
    // 新增
    insert(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addDept(this.ruleForm).then((res) => {
            console.log(res.msg);
            this.dialogVisible = false;
            this.$router.go(0); //刷新页面
          });
        } else {
          console.log("新增失败!!");
          return false;
        }
      });
    },
    handleClose() {
      // this.$refs.ruleForm.resetFields();
      // this.ruleForm = Object.assign({},this.$options.data().ruleForm);
      // console.log("清空表单")
      this.dialogVisible = false;
    },
    handleEdit(row) {
      this.dialogUpdateVisible = true;
      this.ruleForm = Object.assign({}, row); //回显数据
      // this.ruleForm = JSON.parse(JSON.stringify(row));
    },
    //修改
    update(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updateDept(this.ruleForm).then((res) => {
            console.log(res.msg);
            this.dialogUpdateVisible = false;
            // this.$nextTick(() => {
            //   this.ruleForm.name = formName.ruleForm
            // })
            window.location.reload(); //刷新页面
          });
        } else {
          console.log("编辑失败!!");
          return false;
        }
      });
    },
    handleUpdateClose() {
      // this.$refs.ruleForm.resetFields();
      this.ruleForm = Object.assign({}, this.$options.data().ruleForm);
      console.log("清空表单");
      this.dialogUpdateVisible = false;
    },
    //删除
    handleDelete(row) {
      this.$confirm("此操作将永久删除数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteDept(row.id).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            //重新获取列表的接口
            // this.getList();
            this.$router.go(0);
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  created() {
    this.load();
  },
};
</script>

<style lang="less" scoped>
.rowButton {
  text-align: left;
  display: flex;
  justify-content: left;
  .importButton{
    line-height: 20px;
    margin-left: 10px;
  }
}
</style>