import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login'
import Register from '../components/Register'
import Main from '../components/Main'
import AsideMenu from '../components/AsideMenu'
import Employee from '../components/Employee'
import Department from '../components/Department'

Vue.use(VueRouter)

const router = new VueRouter({
    routes: [
        {
            path: '/',
            redirect: '/login'      //redirect是重定向, 将根路径重定向到/login的路径下
        },
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/register',
            name: 'Register',
            component: Register
        },
        {
            path: '/main',
            name: 'Main',
            meta: {
                requireAuth: true,  // 该路由项需要权限校验
              },
            component: Main,
            children: [
                {
                    path: '/asideMenu',
                    name: 'AsideMenu',
                    component: AsideMenu
                },
                {
                    path: '/employee',
                    name: 'Employee',
                    component: Employee
                },
                {
                    path: '/department',
                    name: 'Department',
                    component: Department
                }
            ]
        },
    ],
    mode: "history"
})

//解决vue路由重复导航错误
const originalPush = VueRouter.prototype.push
    //修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
        return originalPush.call(this, location).catch(err => err)
    }
    //获取原型对象上的push函数



//路由守卫
router.beforeEach((to, from, next) => {
    // to and from are both route objects. must call `next`.
    if(to.meta.requireAuth){
        if (to.path === '/login' || to.path === '') {//若要跳转的页面是login页面直接跳转
            next();
        } else {
            let token = localStorage.getItem('Authorization');//获取本地token
            // console.log("beforeEach:",token)
            if (token == null || token == '') {//若token值为空则跳转到登录页面
                next('/login');
            } else {
                next();
                console.log("我进来了")
            }
        }
    }else{
        //// 如果不需要权限校验，直接进入路由界面
        next();
    }
    
})

export default router;