import request from '../utils/request'

export function selectEmp(params) {
    return request({
        url: '/api/employee',
        method: 'GET',
        params: params
    })
}

export function addEmp(data) {
    return request({
        url: '/api/employee',
        method: 'POST',
        data: data
    })
}

export function deleteEmp(id) {
    return request({
        url: '/api/employee/DeleteById/' + id,
        method: 'DELETE'
    })
}

export function upateEmp(data) {
    return request({
        url: '/api/employee',
        method: 'PUT',
        data: data
    })
}

export function removeRowsEmp(ids) {
    return request({
        url: '/api/employee/ids/' + ids,
        method: 'DELETE'
    })
}
