<template>
  <div>
    <h1>Register</h1>
  </div>
</template>

<script>
export default {
    name: "register"

}
</script>

<style>

</style>