import store from "@/store";
import axios from "axios";

const service = axios.create({
  // baseUrl:'http://localhost:7000/',   // api的base_url
  timeout: 15000, // 请求超时时间
});

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
service.interceptors.request.use(
  (config) => {
    console.log("我是请求数据:", config);
    // let token = localStorage.getItem('Authorization');
    let token = store.state.token;
    if (token) {
      //在请求头中设置token
      config.headers.Authorization = `Bearer ${token}`;
      console.log("请求数据token:", config.headers.Authorization);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response 拦截器
// 可以在接口响应后统一处理结果
service.interceptors.response.use(
  (response) => {
    let res = response.data;
    console.log("我是响应数据！!!", res);
         // 如果是返回的文件
    if (response.config.responseType === "blob") {
        return res;
      }
      // 兼容服务端返回的字符串数据
      if (typeof res === "string") {
        res = res ? JSON.parse(res) : res;
      }
      return response.data;
  },
  (error) => {
    console.log("响应error:", error); // for debug
    return Promise.reject(error);
  }
);

export default service;
