import request from "../utils/request";

export function selectDeptName() {
  return request({
    url: "/api/department/selectDeptName",
    method: "GET",
  });
}

export function queryDept(params) {
  return request({
    url: "/api/department",
    method: "GET",
    params: params,
  });
}

export function addDept(data) {
  return request({
    url: "/api/department",
    method: "POST",
    data: data,
  });
}

export function updateDept(data) {
  return request({
    url: "/api/department",
    method: "PUT",
    data: data,
  });
}

export function deleteDept(id) {
  return request({
    url: "/api/department/" + id,
    method: "delete",
  });
}

export function downloadFile() {
  return request({
    url: "/api/file/downloadFile/",
    method: "GET",
    responseType: "blob",
  });
}

export function importFile(formData) {
  return request({
    url: "/api/file/importFile/",
    method: "POST",
    data: formData,
    responseType: "blob",
  });
}
